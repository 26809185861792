/* editor */
.editor-container {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0;
  padding: 0;
  align-items: stretch;
  min-height: 250px;
}

.editor-help {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.5rem 1.2rem 0.5rem 0.5rem;
  display: flex;
}

.editor-help svg {
  cursor: pointer;
  color: var(--color-gray);
  font-size: 1.2rem;
}

/* id passado para o container do editor */
#content {
  width: 100%;
  height: 250px;
  padding: 0px;
}

#content:focus {
  outline: none;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  max-height: 40px;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 0 0;
  padding: 0 10px;
  /* background: var(--color-light-gray); */
  background: var(--color-blue-logo);
  opacity: 1;
}

/* Draft-JS */
.toolbar button {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  margin: 0 0.5rem;
  padding: 8px;
  /* border-right: 1px solid var(--color-gray); */
  border-radius: 0;
}

.toolbar button.active {
  border-style: inset;
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.2), inset -2px -2px 3px rgba(255, 255, 255, 0.2);
  /* background: #d9d9d9; */
}

.toolbar button svg {
  /* fill: var(--color-gray); */
  fill: #d9d9d9;
  color: #d9d9d9;
  font-size: 1rem;
  /* height: 1rem; */
  /* width: 1rem; */
  /* margin: auto; */
}

/* Estilo personalizado para o container do editor */
.public-DraftEditor-content {
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid var(--color-light-gray);
  padding: 5px;
  box-sizing: border-box;
  font-size: 0.9rem;
}

#editor-post .public-DraftEditor-content {
  min-height: 400px;
  max-height: 100%;
}

/* Estilo personalizado para o placeholder */
.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  padding: 5px;
}

/* Estilo personalizado para blocos de texto */
.public-DraftStyleDefault-block {
  /* Seu estilo aqui */
}

/* Estilo personalizado para blocos de citação */
.public-DraftStyleDefault-blockquote,
.public-DraftEditor-content blockquote {
  border-left: 5px solid var(--color-blue-logo);
  margin-left: 0;
  padding-left: 0.5rem;
}

/* Estilo personaLizado para blocos de código */
.public-DraftStyleDefault-pre {
  background-color: #f8f8f8; /* cor de fundo */
  border: 1px solid #e0e0e0; /* borda */
  border-radius: 4px; /* arredondamento das bordas */
  font-family: 'Courier New', monospace; /* fonte monoespaçada */
  font-size: 0.9rem; /* tamanho da fonte */
  line-height: 1.4; /* espaçamento entre linhas */
  padding: 8px 12px; /* preenchimento */
  white-space: pre-wrap; /* manter quebras de linha e espaços */
  overflow-x: auto; /* barra de rolagem horizontal se necessário */
  margin: 0.2rem 0.5rem 0.2rem 0.2rem; /* margens superior e inferior */
}


/* Estilo personalizado para texto em negrito */
.public-DraftStyleDefault-bold {
  /* Seu estilo aqui */
}

/* Estilo personalizado para texto em itálico */
.public-DraftStyleDefault-italic {
  /* Seu estilo aqui */
}

/* Estilo personalizado para texto sublinhado */
.public-DraftStyleDefault-underline {
  /* Seu estilo aqui */
}

/* Estilo personalizado para texto tachado */
.public-DraftStyleDefault-strikethrough {
  /* Seu estilo aqui */
}

/* Estilo personalizado para o placeholder do Draft.js */
.public-DraftEditorPlaceholder-inner::before {
  content: attr(aria-label); /* Exibe o texto do atributo aria-label como placeholder */
  position: absolute; /* Posiciona o placeholder */
  pointer-events: none; /* Ignora eventos de clique no placeholder */
  color: red; /* Cor do texto do placeholder */
  font-style: italic; /* Estilo de fonte do placeholder */
  font-size: 14px; /* Tamanho da fonte do placeholder */
  line-height: 1.4; /* Espaçamento entre linhas */
  padding-left: 5px; /* Espaçamento à esquerda do placeholder */
}

.public-DraftStyleDefault-link {
  color: #3b5998;
  text-decoration: underline;
}

.editor-buttons-container {
  margin: 0.5rem 0 0 0;
}

.editor-toolbar-sticky {
  position: fixed;
  top: 20%;
  left: 0.5rem;
  z-index: 100; 
  flex-direction: column;
  width: 1rem;
  height: auto;
  max-height: none !important;
}

.toolbar.editor-toolbar-sticky button {
  margin: 0;
  margin-left: -5px;
  padding: 7px 0;
}

.editor-spacer {
  flex-grow: 1;
}

.editor-help-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;
  padding: 0 10px;
  background: var(--color-light-gray);
  opacity: 1;
  z-index: 600;
  position: absolute;
  top: 0;
  right: 0;
  /* width: 90%; */
  /* max-height: 300px; */
  overflow-y: auto;
  padding: 2rem;
  font-size: 0.9rem;
  /* border: 1px solid var(--color-gray); */
}

div.editor-help-container > h1 {
  font-size: 1.1rem;
  margin: 0 0 0.5rem 0;
}
/* Draft-JS */

/* 
 media query 
*/
@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .toolbar {
    /* remover max-height */
    max-height: none;
  }

  .toolbar button {
    margin: 0;
    padding: 0.4rem;
  }

  .toolbar button svg {
    font-size: 1rem;
  }
}
