/* upvote e downvote */
.score-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  margin-right: 0.5rem;
}

.score-container svg {
  cursor: pointer;
  height: 1.5rem;
  color: var(--color-gray);
  opacity: 0.5;
  /* border: 1px solid gray; */
}

.score-container svg.arrow-active {
  color: #555;
  /* color: var(--color-blue-logo); */
  opacity: 1;
}

.score-container svg.arrow-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.score-container span.score {
  padding: 0;
  margin: auto 0.3rem;
  font-size: 0.8rem; /* Tamanho do texto do score */
  background: none;
  border: 0;
  color: var(--color-gray);
}

/* media-query */
@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {

  .score-container span.score {
    padding: 0;
    margin: 0.3rem;
  }
}

/* animacao */
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-2px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(2px);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
}

.score-animation.score-up {
  animation-name: shake;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.score-animation.score-down {
  animation-name: shake;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

/* fim upvote e downvote */
