.capa-topico {
  display: flex;
  flex: 1;
  border: 1px solid var(--color-light-gray);
  border-radius: 10px;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.capa-topico svg {
  fill: var(--color-gray);
  color: var(--color-gray);
  font-size: 2rem;
}

.topic-section {
  padding-right: 20px;
}

.topic-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 5px;
}

.topic-item-fixed {
  /* background-color: var(--color-light-gray); */
  /* border-radius: 0 !important; */
}

.topic-item:hover {
  background-color: var(--color-light-gray);
  border-radius: 10px;
}

.topic-item-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* border: 1px solid blue; */
  padding: 20px 10px;
}

.topic-item-titulo {
  padding-bottom: 8px;
  cursor: pointer;
}

.topic-item-titulo h1,
.topic-item-titulo a {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #333 !important;
}

.topic-item-capa {
  display: flex;
  padding: 20px 10px 20px 0px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.topic-item-capa img {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  cursor: pointer;
}

.topic-item-autor {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  color: var(--color-gray);
}

.topic-item-autor a.topic-username-link {
  color: var(--color-gray);
  text-decoration: none;
}

.topic-item-autor a.topic-username-link:hover {
  text-decoration: underline;
}

.topic-item-texto {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1rem;
}

.topic-header-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0.5rem;
}

.topic-header-wrapper {
  background-color: var(--color-light-gray);
  padding: 1rem;
  border-radius: 5px;
}

.topic-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  color: var(--color-gray);
}

.topic-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 0.3rem;
  padding: 0;
  text-transform: normal;
  color: var(--color-gray);
}

.topic-item-nome {
  cursor: pointer;
}

.topic-item-edit {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.topic-item-edit svg {
  margin-right: 10px;
  cursor: pointer;
  color: var(--color-gray);
}

.topic-item-foto {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.topic-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .capa-topico, 
  .topic-item-capa img {
    max-width: 80px;
    min-width: 80px;
    max-height: 80px;
    min-height: 80px;
    margin-bottom: 10px;
  }

  .topic-item-capa {
    flex-direction: column;
    justify-content: flex-start;
  }

  .topic-bar .viewpost-bar-tags {
    margin-top: -30px;
    margin-bottom: 0;
    padding: 0 5px;
    justify-content: center;
  }

  .topic-item-foto {
    width: 30px;
    height: 30px;
  }

  .topic-header-wrapper .topic-title {
    font-size: 1rem;
  }
}
