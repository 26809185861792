.markdown-container {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
}

.post-image {
  max-width: 400px !important;
}

.markdown-container blockquote {
  display: flex;
  margin: 0 0 1rem;
  padding: 0.5rem;
  /* border-left: 3px solid var(--color-blue-logo); */
  border-left: 3px solid rgba(0, 0, 0, 0.2);
  /* color: var(--color-gray); */
  color: #262626;
  background-color: rgba(245, 231, 161, 0.2);
}

.markdown-container p, .markdown-container h2 {
  margin: 1rem 0 !important;
}

/* formatação do markdown do inbox */
.inline-flex .markdown-container p {
  margin: 0 !important;
}

.markdown-container ul, .markdown-container ol {
  margin-top: 0.5rem !important;
  margin-left: 1.5rem !important;
  list-style-position: inside;
}

.markdown-container ul {
  list-style-type: disc;
}

.markdown-container ol {
  list-style-type: decimal;
}

.markdown-container ul li, .markdown-container ol li {
  margin-bottom: 0.8rem;
  text-align: left;
}

.__react_modal_image__header {
  background-color: var(--color-blue-logo) !important;
  opacity: 0.9 !important;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .post-image {
    max-width: 90% !important;
  }
}
