section form {
  line-height: 1.5;
  padding: 10px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  /* background-color: var(--color-light-gray); */
  border-radius: 14px;
}

section form {
  /* padding: 30px 50px; */
}

section#login > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#login form {
  width: 364px;
}

section form label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  display: flex;
}

button {
  /* font-family: var(--body-font-family); */
  font-size: 0.9rem;
}

.vertical-center {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.3rem;
}

section form textarea,
.filter-input,
.navlink-button,
.form-group input,
.form-group textarea,
section form input {
  /* font-family: var(--body-font-family); */
  /* font-size: 0.9rem; */
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-body-text-light);
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

form input:focus,
/* form textarea:focus, */
/* .form-group textarea:focus, */
.form-group input:focus {
  border: 2px solid var(--color-blue-logo);
  outline: 0;
}

.form-group textarea {
  resize: none;
}

#skills textarea {
  height: 350px;
}

input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  margin: 0;
  margin-right: 0.5rem;
}

select {
  /* font-family: var(--body-font-family); */
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-body-text-light);
  /* width: 100%; */
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  background-color: var(--color-white);
}

.navlink-button,
section form button {
  font-size: 0.9rem;
  font-weight: 400;
  /* line-height: 1.5; */
  color: var(--color-body-text-dark);
  background-color: var(--color-blue-logo);
  border: 0px solid #e3e3e3;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  cursor: pointer;
}

.contact-container {
  padding: 10px;
}

.contact-list {
  margin: 20px;
  list-style: none;
  padding: 0;
}

.contact-list li {
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  align-items: center;
}

.contact-list li svg {
  margin-right: 8px;
  color: var(--color-blue-logo);
  fill: var(--color-blue-logo);
}

.formError {
  color: red;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

.is-invalid,
.is-invalid:focus {
  /* background-color: #FFE5CA; */
  border-color: #DF2E38;
  border-left: 8px solid #DF2E38;
  /* border-left: 8px solid var(--color-blue-logo); */
}

#contato a,
#contato a:visited {
  color: var(--color-body-text-light);
  text-decoration: none;
}

.formlogin-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.password-container {
  /* display: inline-flex; */
  /* flex: 1; */
  /* width: 100%; */
}

.password-container input {
  /* display: block; */
}

.password-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 45%;
    transform: translateY(-50%);
    cursor: pointer;
}

.toggle-password svg {
  cursor: pointer;
  color: var(--color-gray);
  width: 1.1rem;
  height: 1.1rem;
  opacity: 0.5;
}

.smallform-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 500px;
}

.char-counter {
  /* float: right; */
  padding: 5px;
  font-size: 12px;
  background-color: #F2E5E5;
  border-radius: 5px;
  /* margin-top: -20px; */
  /* margin-right: 30px; */
  z-index: 200;
}

.error-message {
  color: red;
  margin: -1rem 0.5rem 0.5rem 0.5rem;
}

.container-senha-e-confirmar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container-senha {
  width: 48%;
}

.container-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.container-button button {
  margin: 0;
}

/* otc */
.otc {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.otc fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}

.otc fieldset div {
	display: flex;
	align-items: center;
}

.otc legend {
	margin: 0 auto 1em;
  color: var(--color-blue-logo);
}

.otc-number {
	width: 2.8rem;
	line-height: 1;
	margin: .1em;
	padding: 8px 4px 4px;
	font-size: 2.65em;
	text-align: center;
	appearance: textfield;
	-webkit-appearance: textfield;
	border: 2px solid var(--color-light-gray);
  color: #222;
	border-radius: 4px;
}

.otc-number:focus {
	border: 2px solid var(--color-light-gray);
}

input.otc-number::-webkit-outer-spin-button,
input.otc-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 2 group of 3 items */
input[class="otc-number"]:nth-child(n+4) {
	order: 2;
}

/* .otc div::before { */
  /* content: ''; */
  /* height: 2px; */
  /* width: 24px; */
  /* margin: 0 .25em; */
  /* order: 1; */
  /* background: #BBBBFF; */
/* } */

.otc label {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

#cadastro .MuiTextField-root {
  margin-bottom: 1.2rem;
}

body section div.MuiInputBase-root textarea {
  border: 0;
}


@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .container-senha-e-confirmar {
    flex-direction: column;
  }

  .container-senha {
    width: 100%;
  }

  .container-button {
    justify-content: center;
  margin-bottom: 1rem;
  }
}
