.home-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 728px;
  padding-right: 50px;
  border-right: 1px solid var(--color-light-gray);
  padding-top: 20px;
}

.home-post-item {
  display: block;
}

.home-post-item p {
  margin: 0.5rem 0 1rem;
}

.home-post-item hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid var(--color-light-gray);
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-post-item-body {
  display: flex;
  flex: 1;
  flex-direction: row;
  text-rendering: geometricPrecision;
  text-align: justify;
}

.home-post-item-body:hover {
  /* background-color: var(--color-light-gray); */
}

.home-post-item-titulo {
  padding-bottom: 8px;
  cursor: pointer;
}

.home-post-item-titulo h1,
.home-post-item-titulo a {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #333 !important;
}

.home-post-item-autor {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  color: var(--color-gray);
}

.home-post-item-foto {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.home-post-item-foto,
.home-post-item-autor {
  cursor: pointer;
}

.home-post-item-texto {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.home-post-item-texto p {
}

.home-post-item-capa {
  display: flex;
  padding: 0 20px;
  text-align: center;
  justify-content: center;
}

.home-post-item-capa img {
  width: 180px;
  height: 180px;
  max-width: 180px;
  max-height: 180px;
  /* height: 140px; */
  border-radius: 6px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.6); */
  cursor: pointer;
}

.banner-home {
  display: flex;
  margin: 0.5rem 0 1rem;
}

.banner-home img {
  border-radius: 15px;
}

@media (max-width: 768px), (orientation: landscape) and (max-height: 500px) {
  .home-post-item-capa img {
    width: 120px;
    height: 120px;
  }

  .home-post-item-capa {
    padding: 0 0 20px;
  }

  .home-post-item-texto {
    /* font-size: 0.8rem; */
  }

  .home-post-item-titulo h1 {
    /* font-size: 1rem; */
  }

  .home-post-item-body {
    text-align: left;
  }
}
