.popper-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
  /* background-color: var(--color-light-gray); */
  background-color: white;
  color: var(--color-body-text-light);
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
  max-width: 400px;
  margin-left: 30px;
  font-size: 0.85rem;
}

.popper-box a,
.popper-box a:visited {
  font-weight: 600;
  text-decoration: none;
  color: var(--color-blue-logo);
}

body.dark .popper-box {
  background-color: var(--color-dark-gray);
  color: var(--color-body-text-dark);
}
