.viewpost-section {
  padding-right: 20px;
}

.viewpost-content {
  /* margin-top: 20px; */
}

.viewpost-content a {
  font-weight: normal;
}

.viewpost-header-wrapper {
  background-color: var(--color-light-gray);
  padding: 1rem;
  border-radius: 5px;
}

.viewpost-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  color: var(--color-gray);
}

.viewpost-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-transform: normal;
}

.viewpost-description {
  padding: 1rem 0;
  /* border-bottom: 1px solid var(--color-light-gray); */
}

.viewpost-item-foto {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.viewpost-item-nome {
  cursor: pointer;
}

.viewpost-item-edit {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.viewpost-item-edit svg {
  margin-right: 10px;
  cursor: pointer;
  color: var(--color-gray);
}

.viewpost-bar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0px 20px;
  /* border-bottom: 1px solid var(--color-light-gray); */
}

.viewpost-bar-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  /* margin-top: 20px; */
}

.viewpost-bar-tags span.viewpost-tag {
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  border-radius: 1.2rem;
  background-color: var(--color-light-gray);
  padding: 0.3rem 0.5rem;
  cursor: auto;
  font-size: 0.8rem;
  /* max-height: 1.4rem; */
}

.viewpost-tag svg {
  margin-left: 0.5rem;
  /* font-size: 1rem; */
  fill: black;
  cursor: pointer;
}

.taglist-button {
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  border-radius: 1.2rem;
  background-color: transparent;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  font-size: 0.8rem;
  border: 1px solid var(--color-light-gray);
  color: var(--color-gray);
}

.taglist-button svg {
  margin-right: 0.3rem;
}

.viewpost-content h1, .viewpost-content h2, .viewpost-content h3, .viewpost-content h4, .viewpost-content h5, .viewpost-content h6 {
  margin-top: 20px;
  margin-block-end: 0.3em;
}

/* viewpost-content primeiro child */
.viewpost-content > div > *:first-child {
  margin-top: 0px;
}

.comments-count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.comments-count-container svg {
  cursor: pointer;
  height: 1.5rem;
  color: var(--color-gray);
  /* opacity: 0.5; */
  /* border: 1px solid gray; */
}

.comments-count-container span {
  padding: 0;
  margin: auto 0.3rem;
  font-size: 0.8rem; /* Tamanho do texto do score */
  background: none;
  border: 0;
  color: var(--color-gray);
}

/* drop down de sugestão de tags */
.tag-suggestions {
  top: 42px;
  background-color: #fff;
  /* border: 1px solid blue; */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.suggested-tag {
  cursor: pointer;
  padding: 8px 12px;
}

.suggested-tag:hover {
  background-color: #f1f1f1;
}

.dashboard-tag {
  margin-right: 0.8rem;
  border-radius: 1.2rem;
  background-color: var(--color-light-gray);
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.dashboard-tag button {
  background: none;
  border: none;
  color: var(--color-body-light-text);
  cursor: pointer;
  font-weight: bold;
  margin-left: 4px;
}

.dashboard-tag button:hover {
  color: #ccc;
}

.suggested-tag.selected {
  background-color: #f0f0f0;
}

.image-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-container p {
  font-size: 0.8rem;
  color: var(--color-gray);
  /* font-style: italic; */
  margin: 0.5rem 0;
  background: var(--color-light-gray);
  padding: 0.5rem;
  border-radius: 15px;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .viewpost-bar {
    padding-bottom: 5px;
  }

  .viewpost-bar-tags {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}
