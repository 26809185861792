#header {
  position: fixed;
  width: 100%;
  display: flex;
  flex: 1;
  top: 0;
  z-index: 100;
  background-color: white;
  transition: transform 0.5s ease-in-out;
  height: var(--header-height);
  min-height: var(--header-height);
  max-height: var(--header-height);
  /* border-bottom: 1px solid var(--color-light-gray); */
  font-family: var(--header-font-family);
  /* background-color: var(--color-header-background); */
  background-color: black;
}

.logo-container {
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 0;
  /* min-width: 180px; */
}

.logo-container img {
  margin: 0;
  margin-left: 1.3rem;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* imagem em grayscale */
  /* filter: grayscale(100%); */
}

.logo-container span {
  /* border: 1px solid red; */
  position: absolute;
  left: 70px;
  bottom: 18px;
  color: whitesmoke;
  font-family: var(--header-font-family);
  font-size: 0.7rem;
}

#desktop-menu {
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
}

.navbar-container {
  display: flex;
  flex: 1;
  /* width: 100%; */
  /* height: 100%; */
  align-items: center;
  padding: 0;
  z-index: 1800;
  /* border: 1px solid yellow; */
}

.left-section {
  display: flex;
  height: 100%;
  /* border: 1px solid blue; */
}

.middle-section {
  display: flex;
  flex: 1;
  /* width: 100%; */
  height: 100%;
  justify-content: center;
  /* alinhar seus itens ao centro */
  align-items: center;
  /* border: 1px solid yellow; */
  margin-left: 5rem;
}

.right-section {
  display: flex;
  flex: 1;
  /* max-width: 250px; */
  /* height: 100%; */
  align-items: center;
  /* border: 1px solid blue; */
}

/* mobileNavLinks.css */
.nav-links-container {
  height: 100%;
  display: flex;
  /* flex: 1; */
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid green; */
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0;
  padding: 0 20px;
  height: 100%;
}

a.nav-item:link,
a.nav-item:visited {
  /* color: #777; */
  color: whitesmoke;
}

a.nav-item:hover:not(.nav-item-write):not(.nav-item-icon) {
  background-color: whitesmoke;
  /* color: var(--color-blue-logo); */
  color: black;
  opacity: 0.8;
}

a.nav-item.active:not(.nav-item-write):not(.nav-item-icon) {
  background-color: whitesmoke;
  /* color: var(--color-blue-logo); */
  color: black;
  opacity: 0.8;
}

.nav-item-login {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid green; */
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  padding: 0 20px;
  height: calc(var(--header-height) * 0.5);
  /* background-color: var(--color-blue-logo); */
  background-color: whitesmoke;
  border-radius: 15px;
  min-width: fit-content;
}

a.nav-item-login,
a.nav-item-login:visited {
  color: var(--color-blue-logo) !important;
}

.nav-item-login:first-child {
  margin-right: 10px;
}

a.nav-item-login:link,
a.nav-item-login:visited {
  color: white;
}

a.nav-item-write {
  border: 1px solid whitesmoke;
  border-radius: 10px;
  padding: 0.4rem 0.6rem;
  font-size: 0.8rem;
  height: calc(var(--header-height) * 0.3);
  /* font-size: 0.7rem; */
  text-transform: none;
}

.nav-item-write svg {
  margin-left: 0.2rem;
  /* font-size: 1rem; */
}

a.nav-item-write:hover {
  color: whitesmoke;
}

.account-links-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.nav-item-img {
  /* width: calc(var(--header-height) * 0.6); */
  /* height: calc(var(--header-height) * 0.6); */
  border-radius: 50%;
  cursor: pointer;
}

.nav-icon {
  /* color: #999; */
  color: whitesmoke;
  font-size: 1.5rem;
  font-weight: 400;
  opacity: 1;
  cursor: pointer;
  margin-left: 0.8rem;
}

.nav-icon-down {
  font-size: 1.2rem;
  margin-left: -15px;
  cursor: pointer;
}

.logo-container {
  display: flex;
  height: 100%;
  margin: 0;
  margin-left: 10px;
  align-items: center;
  justify-content: flex-start;
}

.nav-item-search {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-left: 2rem;
  position: relative;
}

.nav-search-box {
  /* position: absolute; */
  /* top: 0; */
  /* right: -220px; */
  /* border: 1px solid red; */
  display: flex;
  flex: 1;
  /* z-index: 1400; */
  /* background-color: white; */
  /* width: 220px; */
  align-items: center;
  height: 100%;
}

.nav-item-search .search-box {
  background-color: white;
  margin: 0;
  border: none;
}

.nav-item-search svg {
  /* color: var(--color-blue-logo); */
  color: whitesmoke;
  font-size: 1.2rem;
  cursor: pointer;
  opacity: 0.8;
}

.search-box-open svg {
  /* color: var(--color-blue-logo); */
  color: var(--color-gray);
}

.mobile-menu {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  background-color: #fff;
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 80px;
  left: 0;
}

.spacer {
  margin: 20px 0;
  border-bottom: 1px solid #333;
  width: 100%;
  align-self: center;
}

/* navLinks.css */
.nav-links-container {
  display: flex;
  flex: 1;
  height: 100%;
  margin-left: 30px;
  justify-content: flex-start;
}

.nav-links-container-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* menu suspenso do usuario */
.floating-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid var(--color-light-gray);
  padding: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 264px;
  animation: slide-down 0.3s ease-in-out;
  z-index: 1000;
}

@keyframes slide-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.floating-menu.slide-up {
  animation: slide-up 0.3s ease-in-out;
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-10px);
    opacity: 0;
  }
}

.floating-menu__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 30px;
  text-decoration: none;
  color: #333;
  font-size: 0.8rem;
}

.floating-menu > .floating-menu__item:first-child {
  display: flex;
  border-bottom: 1px solid var(--color-light-gray);
  background-color: var(--color-blue-logo);
  margin: 0;
  color: whitesmoke;
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: uppercase;
  justify-content: center;
}

.floating-menu > .floating-menu__item:first-child:hover {
  background-color: var(--color-blue-logo);
}

/* linha divisora antes do Sair */
.floating-menu a:last-child div {
  border-top: 1px solid var(--color-light-gray);
}

.floating-menu a,
.floating-menu a:visited {
  color: #777;
  text-decoration: none;
}

.floating-menu__item svg {
  padding-right: 10px;
  font-size: 1.5rem;
}

.floating-menu__item:hover {
  background-color: #f5f5f5;
}
/* fim menu suspenso do usuario */

/* menu suspenso de notificações */
.notifications-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid var(--color-light-gray);
  padding: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 434px;
  animation: slide-down 0.3s ease-in-out;
  z-index: 1000;
}

.notifications-menu.slide-up {
  animation: slide-up 0.3s ease-in-out;
}

.notifications-menu__item {
  display: flex;
  padding: 0;
  text-decoration: none;
  color: #777;
  font-size: 0.8rem;
  align-items: flex-start;
}

.notifications-menu__header {
  display: flex;
  border-bottom: 1px solid var(--color-light-gray);
  background-color: var(--color-blue-logo);
  margin: 0;
  padding: 10px 30px;
  color: whitesmoke;
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
}

.notifications-menu__body {
  display: flex;
  flex-direction: column;
  /* padding: 10px 30px; */
  max-height: 500px;
  overflow-y: auto;
}

.notifications-menu__footer {
  display: flex;
  /* space-between */
  justify-content: space-between;
  padding: 10px 30px;
  border-top: 1px solid var(--color-light-gray);
}

.notifications-menu a,
.floating-menu a:visited {
  color: #777;
  text-decoration: none;
}

.notifications-menu__footer a,
.notifications-menu__item__button {
  color: #777;
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
}

.notifications-menu__item svg {
  padding: 10px 0 0 10px;
  font-size: 1.2rem;
}

.notifications-menu__item.read svg {
  color: white;
}

.notifications-menu__item.unread svg {
  color: #de4e4e;
  cursor: pointer;
}

.notifications-menu__item__content:hover {
  /* background-color: #f5f5f5; */
}

.notifications-menu__item__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  /* background-color: #f5f5f5; */
}

.notifications-menu__item__content p {
  display: flex;
  margin: 0.4rem 0 0;
  padding: 0;
  font-size: 0.7rem;
  justify-content: flex-start;
}

.notifications-menu__item__action {
  display: flex;
  align-items: flex-start;
}

.notifications-menu__item .notifications-menu__item__action svg {
  color: #777;
  cursor: pointer;
  margin-right: 0.5rem;
}

.notification-badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -2px;
  right: -5px;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 0.8rem;
  min-width: 1.2rem;
  min-height: 1.2rem;
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}
/* fim menu suspenso de notificações */

#mobile-menu-suspenso .nav-item {
  padding: 10px;
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 20;
}

.close-menu,
.toggle-menu {
  display: none;
  margin: 0;
}

#mobile-menu svg {
  fill: var(--color-body-text-dark);
  font-size: 1.4rem;
}

.toggle-theme {
  font-size: 19px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
}

.toggle-theme span {
  font-size: 16px;
}

.toggle-theme svg {
  color: var(--color-body-text-dark);
}

#mobile-menu-suspenso {
  background-color: #fff;
}

#mobile-menu-suspenso .nav-item {
  color: var(--color-body-text-light);
}

.nav-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item-icon svg {
  width: 30px;
  height: 30px;
}

.nav-item-img {
  width: 38px;
  height: 38px;
  padding: 0 10px 0;
}

#swipe-contender {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  z-index: 1000;
}

#swipe-contender-opened {
  position: fixed;
  right: 0;
  top: 70px;
  /* transform: translateY(-50%); */
  width: 30px;
  height: 30px;
  z-index: 1201;
  padding: 0;
}

#swipe-contender-opened svg {
  fill: var(--color-blue-logo);
  font-size: 1rem;
  width: 15px;
  height: 15px;
  z-index: 1201;
}

#swipe-bar {
  position: fixed;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 12px;
  height: 230px;
  background-color: var(--color-blue-logo);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#swipe-bar-opened {
  position: fixed;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 12px;
  height: 230px;
  background-color: var(--color-blue-logo);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-mobile-header {
  display: flex;
  width: 100%;
  min-height: 50px;
  background-color: var(--color-blue-logo);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  align-items: center;
  justify-content: flex-end;
  color: white;
}

.sidebar-mobile-header span {
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
}

.MuiSwitch-track {
  background-color: #fff !important;
}

.Mui-checked + .MuiSwitch-track {
  background-color: #1976d2 !important;
}

/* botão cadastre-se no header */
.account-links-container .MuiChip-root {
  background: rgba(255, 255, 255, 0.16) !important;
  color: white !important;
}

/* @media (max-width: 768px), */
@media (max-width: 992px), (orientation: landscape) and (max-height: 500px) {
  .main-container {
    margin-top: 90px;
    padding-top: 0;
  }

  .notifications-menu {
    width: auto;
    max-width: 100%;
    z-index: 1999;
  }

  .account-links-container {
    padding-right: 10px;
  }

  #left-menu {
    padding-left: 10px;
  }

  #right-menu {
    justify-content: flex-end;
    padding-right: 10px;
    display: flex;
    flex: 1;
    align-items: center;
  }

  #mobile-menu-suspenso {
    background-color: whitesmoke;
    color: var(--color-body-text-light);
    padding: 0;
    z-index: 1999;
  }

  #mobile-menu-suspenso .nav-item,
  #mobile-menu-suspenso .nav-item:visited {
    padding-left: 20px;
    padding-top: 10px;
    display: block;
    color: var(--color-body-text-light);
  }

  .logo-container {
    margin-left: 0;
  }

  .nav-links-container {
    flex: 0;
    justify-content: flex-end;
    padding: 0 20px 0 10px;
    margin-left: 0;
  }

  .nav-item {
    padding: 0 10px;
    font-size: 0.8rem;
  }

  .nav-item-icon {
    align-items: flex-end;
    padding-left: 5px;
    /* background: gray; */
    height: 100%;
  }

  .nav-item-icon svg {
    width: 28px;
    height: 28px;
    margin: 0;
    padding: 0;
  }

  .nav-item-img {
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;
  }

  .nav-item-img {
    /* margin-left: 10px; */
    /* padding-left: 0; */
    padding-right: 0;
    /* padding-left: 15px; */
  }

  .navbar-container {
    position: relative;
    z-index: 1000;
    min-height: 60px;
    background-color: inherit;
  }

  .nav-links-container-mobile {
    position: fixed;
    width: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    margin: 0;
    justify-content: center;
    z-index: 999;
    top: 60px;
    /* quero uma transição de visivel para invisivel */
    transition: transform 0.5s ease-in-out;
    /* border-bottom: 1px solid #ccc; */
    background-color: inherit;
  }

  .nav-links-container-mobile a.nav-item {
    color: #fff;
  }

  .nav-links-container-mobile a.nav-item:hover {
    border-radius: 15px;
  }

  .nav-links-container-mobile a.nav-item.active {
    color: #000 !important;
    border-radius: 15px;
  }

  #header {
    max-height: none;
    height: auto;
    max-width: 100vw;
  }
}
