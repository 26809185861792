@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* fontes */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Source+Sans+Pro&display=swap'); */

/* cores */
@import url('./css/colors.css');

/* global */
@import url('./css/global.css');

/* HEADER */
@import url('./css/header.css');

/* HOME */
@import url('./css/home.css');

/* SIDEBAR */
@import url('./css/sidebar.css');

/* POSTS */
@import url('./css/posts.css');

/* FOOTER */
@import url('./css/footer.css');

/* TOASTIFY */
@import url('./css/toastify.css');

/* SCORE */
@import url('./css/score.css');

/* COMMENTS */
@import url('./css/comments.css');

/* MARKDOWN */
@import url('./css/markdown.css');

/* EDITOR */
@import url('./css/editor.css');

/* ANEXOS */
@import url('./css/anexos.css');

/* USER */
@import url('./css/user.css');

/* VIDEO */
@import url('./css/video.css');

/* FORUM */
@import url('./css/forum.css');

/* INBOX */
@import url('./css/inbox.css');

/* BRINQUEDOS */
@import url('./css/brinquedo.css');

/* CURSOS */
@import url('./css/cursos.css');

.viewpost-bar-social {
}

.viewpost-bar-social svg {
  margin-right: 10px;
  cursor: pointer;
  /* color: var(--color-gray); */
  color: var(--color-blue-logo);
}

.social-bar {
  display: flex;
  flex-wrap: nowrap;
}

.markdown-image {
  display: flex;
  flex: 1;
  justify-content: center;
}

.markdown-image img {
  max-width: 700px;
  height: auto;
}

.code-block > pre {
  /* padding-top: 3.5rem !important; */
  border-radius: 15px;
}

/* FORMS */
@import url('./css/forms.css');

/* BREACRUMB */
@import url('./css/breadcrumb.css');

/* DASHBOARD */
@import url('./css/dashboard.css');

/* POPPER */
@import url('./css/popper.css');

/* SLIDER */
@import url('./css/slider.css');

/* GARAGEM */
@import url('./css/garagem.css');

/* dark mode */
@import url('./css/dark.css');

/* 
 media query 
*/
@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .main-container {
    display: flex;
    padding: 0px 1rem;
    margin-top: 90px;
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
  }

  .main-container section {
    padding: 1rem;
  }

  .main-container > *:not(.home-sidebar) {
    padding-top: 1.5rem;
    padding-right: 1rem;
  }

  .code-block > pre {
    padding-top: 3.5rem !important;
    border-radius: 15px;
    /* fazer texto quebrar linha */
    white-space: pre-wrap !important;
  }

  .bots-list {
    padding: 0;
  }

  .bot-card {
    width: 40%;
  }

  .toggle-menu {
    display: flex;
    flex: 1;
    margin: 0;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .toggle-menu svg {
    fill: var(--color-light-gray);
    height: 100%;
    font-size: 1.4rem;
    margin-right: 0px;
  }

  #home-depoimentos {
    flex-direction: column;
  }

  #home-depoimentos p:nth-child(2) {
    display: none;
  }

  .toggle-theme {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  #login form {
    width: auto !important;
  }

  #footer {
    padding: 0;
    margin: 0;
  }

  #footer .row {
    flex-direction: column;
    padding: 10px 20px;
  }

  #footer .row .col {
    flex: 1 !important;
  }

  .float-right,
  .float-left {
    float: none;
    width: 90%;
    margin: 10 auto;
    display: block;
    margin-bottom: -20px;
  }

  .popper-box {
    margin-left: 10px;
    max-width: 80%;
  }

  .popper-box a,
  .popper-box a:visited {
    color: var(--color-yellow);
  }

  .viewmestre-personalidades {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .personalidades-list {
    justify-content: center;
    padding: 0;
  }

  .react-confirm-alert {
    display: flex;
    max-width: 90%;
  }

  .react-confirm-alert-body h1 {
    font-size: 1.5rem;
  }
}
