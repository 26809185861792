#garagem img {
  max-width: 50%;
  align-self: center;
}

.google-play-badge a {
  display: flex;
  justify-content: center;
  align-items: center;
}

#garagem h2, #pagamento h2 {
  font-size: 2rem;
}

.planos h2 {
  cursor: pointer;
}

#garagem div.google-play-badge > a > img {
  max-width: 180px;
}

.garagem-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 5px;
  /* background-color: var(--color-blue-footer); */
  background-color: rgb(57, 81, 179);
  color: #fff !important;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.planos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  /* height: 100%; */
  margin: 0 auto 2rem auto;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  color: #333;
  text-align: center;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  gap: 1rem;
  flex-wrap: wrap;
}

.plano {
  width: 100%;
  flex-basis: calc(33.333% - 1rem);
  /* height: 100%; */
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  /* cursor: pointer; */
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 1px solid #ccc;
  /* border-top: 5px solid #ccc; */
  /* border-bottom: 5px solid #ccc; */
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
  background-color: #fff;
}

.plano p {
  width: 80%;
  margin: 1rem auto;
  text-align: center;
}

.plano-features p {
  display: flex;
  align-items: center;
}

.plano-features svg {
  margin-right: 0.5rem;
}

.plano svg {
  fill: rgba(0, 128, 0, 1);
}

.plano h2 {
  font-size: 1.5rem !important;
  font-weight: 300;
  color: whitesmoke;
  margin: 0;
  padding: 0;
  background-color: rgba(205, 127, 50, 1); /* bronze */
  border-bottom: 1px solid #ccc;
  padding: 1rem;
}

.plano-preco-cortado {
  color: #555;
  text-decoration: line-through;
}

.plano-preco-final {
  font-weight: 600;
  font-size: 2rem;
  /* nao deixe a palavra cortar */
  white-space: nowrap;
}

.planos > .plano > button.plano-button {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  border: 0;
}

.plano-divider {
  margin: 0 auto;
  width: 80%;
  height: 1px;
  background-color: #ccc;
}

.plano-bronze {
  /* border: 1px solid rgba(205, 127, 50, 1); [> bronze <] */
  border: 1px solid #ccc;
}

.plano-bronze h2 {
  background-color: rgba(205, 127, 50, 1); /* bronze */
}


.plano-prata {
  /* border: 1px solid var(--color-blue-footer); */
  border: 1px solid #ccc;
}

.plano-prata h2 {
  background-color: var(--color-blue-footer);
}

.plano-ouro {
  /* border: 1px solid rgba(0, 128, 0, 1); [> ouro <] */
  border: 1px solid #ccc;
}

.plano-ouro h2 {
  background-color: rgba(0, 128, 0, 1); /* ouro */
}

.plano-diamante {
  /* border: 1px solid rgba(128, 0, 128, 1); [> diamante <] */
  border: 1px solid #ccc;
}

.plano-diamante h2 {
  /* give some purple */
  background-color: rgba(128, 0, 128, 1); /* diamante */
}

.plano-vitalicio h2 {
  background-color: rgba(125, 0, 30, 1); /* dark red */
}

#adesao h2 {
  margin-bottom: 1rem;
}

.adesao-list li {
  margin-bottom: 0.5rem;
}

.info-p {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(255, 165, 0, 0.2);
  margin-bottom: 1rem;
}

.info-p-green {
   /* background  light green */
  background-color: rgba(144, 238, 144, 0.5);
}

.info-p-blue {
  /* background  light blue */
  background-color: rgba(173, 216, 230, 0.5);
}

.info-p svg {
  width: 2rem;
  height: 2rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.info-p ul {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
}

.garagem-link-button {
  background-color: rgba(155, 0, 0, 0.9);
  color: #fff !important;
  padding: 0 0.5rem;
  border-radius: 5px;
}

#adesao select {
  max-width: 100%; /* Limita a largura máxima do select */
  overflow: hidden; /* Previne que o texto ultrapasse a largura */
  text-overflow: ellipsis; /* Adiciona reticências se o texto for muito longo */
  white-space: nowrap; /* Impede que o texto quebre em várias linhas */
}

.green-info {
  font-size: 0.8rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
  color: rgba(0, 128, 0, 1);
}

.before-cupom {
  text-decoration: line-through;
  color: var(--color-gray);
  margin-left: 0.5rem;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .planos {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;
  }
  .plano {
    flex-basis: 100%;
  }


  #adesao select {
    /* max-width: 350px; */
  }
}
