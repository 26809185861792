.dashboard-cursos-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.container-curso {
  margin-bottom: 1rem;
}

.curso-profile {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  /* border: 1px solid red; */
}

.curso-profile__column {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* border: 1px solid blue; */
}

.curso-profile__column:first-child {
  max-width: 180px;
}

.curso-profile__column p {
  margin: 1rem 0 0.5rem;
  /* padding: 0; */
}

.curso-profile__image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 0.3rem;
}

.curso-profile__menu {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.curso-profile__follow-btn,
.curso-profile__message-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1px;
  padding: 0.5rem 1rem 0.5rem 3rem;
  border: none;
  /* border: 1px solid white; */
  /* border-radius: 4px; */
  background-color: #c9e5c9;
  color: #000;
  cursor: pointer;
  /* text-transform: uppercase; */
  font-size: 0.8rem;
  line-height: 1.5rem;
}

.curso-profile__message-btn {
  background-color: #c9e5c9;
  /* background-color: #e3f2e3; */
  /* border-top: 1px solid var(--color-light-gray); */
}

.curso-profile__column svg {
  margin-right: 0.5rem;
}

.curso-profile__follow-btn:hover,
.curso-profile__message-btn:hover {
  opacity: 0.8;
}

.curso-profile__send-message-title,
.curso-profile__cursoname {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: var(--color-light-gray);
  background-color: var(--color-blue-logo);
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--color-white);
}

.curso-profile__social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 0rem;
  /* border: 1px solid var(--color-light-gray); */
}

.curso-profile__social-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-start; */
  justify-content: center;
  padding: 0.5rem 0.2rem;
  border: 1px solid var(--color-light-gray);
  font-size: 0.9rem;
}

.curso-profile__social-item:hover {
  background-color: var(--color-light-gray);
}

.curso-profile__social-item svg {
  fill: var(--color-blue-logo);
  color: var(--color-blue-logo);
}

.curso-profile__social-item a {
  word-break: break-all;
}

.curso-profile__website,
.curso-profile__website a,
.curso-profile__website a:visited {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  /* color: var(--color-blue-logo); */
  color: var(--color-body-light-text);
}

.curso-profile__bio {
  /* border: 1px solid var(--color-light-gray); */
  padding: 0 1rem 1rem;
  font-size: 1rem;
}

.curso-profile__register-date {
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 0.9rem;
}

.curso-profile__artigos-list {
  font-size: 0.9rem;
}

.curso-profile__tabs {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-light-gray);
  margin-bottom: 1rem;
}

.curso-profile__tabs h2 {
  display: inline-block;
  margin-right: 0.3rem;
  margin-bottom: 0;
  cursor: pointer;
}

.curso-profile__artigos-title {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 1rem 0;
  padding: 5px;
  display: inline-block;
  /* position: relative; */
  color: whitesmoke;
  background: var(--color-blue-logo);
}

.curso-profile-inactive-tab {
  background-color: var(--color-gray) !important;
}

/* curso-information */

.curso-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* font-size: 1rem; */
}

.curso-information-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.curso-information-field label {
  font-weight: 400;
  color: var(--color-gray);
  margin: 10px 0 0px;
  font-size: 0.9rem;
}

.curso-information-field input[type="text"] {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  padding: 10px 10px;
  /* font-size: 1rem; */
  /* width: 100%; */
}

.curso-information-input-prefix {
  border: 0px solid var(--color-light-gray);
  border-radius: 0px;
  padding: 10px 10px;
  background-color: var(--color-light-gray);
  color: var(--color-gray);
  z-index: 20;
  margin-right: -5px;
}

.curso-information-field input[type="checkbox"] {
  margin-right: 5px;
}

.curso-information-field input[readonly] {
  background-color: transparent;
  border: none;
  cursor: default;
  font-weight: 400;
  color: var(--color-gray);
}

.curso-information-field input {
  color: var(--color-dark-gray);
  flex-grow: 1;
  font-size: 0.9rem;
}

.curso-information-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px 0;
  font-size: 0.9rem;
}

.right-column > .curso-information-header:not(:first-child) {
  margin-bottom: 0.5rem;
}

.curso-information-header-title {
  min-width: 9rem; /* ajuste este valor conforme necessário */
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-gray);
}

.curso-information-header-name {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-dark-gray);
}

.curso-information-field textarea {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 0.9rem;
  resize: none;
  /* 5 linhas */
  height: 13rem;
}

.curso-information-input-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 0.9rem;
}
/* fim curso-information */

.curso-picture {
  width: 160px;
  border: 2px solid var(--color-light-pink);
}

.aula-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.aula-active {
  background-color: var(--color-medium-gray);
  border-radius: 8px;
}

.aula-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.aula-controls-button {
  cursor: pointer;
}

.aula-controls-button-disabled {
  cursor: default;
  opacity: 0.4;
}

.aula-locked {
  cursor: default;
  opacity: 0.4;
}

.aula-container:not(.aula-locked):hover {
  background-color: var(--color-medium-gray);
  border-radius: 8px;
}

.aula-thumbnail {
  position: relative;
  flex: 0 1 auto;
  min-width: 180px;
  max-width: 180px;
}

.curso-sidebar .aula-thumbnail {
  min-width: 120px;
  max-width: 120px;
}

.aula-thumbnail img {
  width: 180px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
}

.aula-thumbnail-status {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.2rem 0.5rem;
  background-color: var(--color-light-gray);
  border-radius: 4px;
  font-size: 0.8rem;
}

.aula-info {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.modulo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modulo-thumbnail {
  flex: 0 1 auto;
  padding: 1rem;
}

.modulo-titulo {
  flex-grow: 1;
}

.modulo-progresso {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.modulo-thumbnail span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light-gray);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  font-size: 1.5rem;
}

.autor-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.autor-header {
  display: flex;
  /* flex: 0 1 auto; */
  padding: 1rem 0;
  flex-direction: row;
  gap: 0.5rem;
}

.autor-header h3,
.autor-header p {
  margin: 0;
}

.autor-header p {
  opacity: 0.7;
}

.autor-bio {
  /* flex-grow: 1; */
}

.custom-check-circle {
  color: #fff;
  background-color: var(--color-green);
  border-radius: 50%;
  width: 1rem !important; /* Tamanho do círculo */
  height: 1rem !important; /* Tamanho do círculo */
}

.custom-check-circle-action {
  color: #fff;
  background-color: var(--color-green);
  border-radius: 50%;
  width: 0.9rem !important; /* Tamanho do círculo */
  height: 0.9rem !important; /* Tamanho do círculo */
}

.custom-uncheck-circle-action {
  color: var(--color-text);
  background-color: none;
  border: 1px solid var(--color-text);
  border-radius: 50%;
  width: 0.8rem !important; /* Tamanho do círculo */
  height: 0.8rem !important; /* Tamanho do círculo */
  opacity: 0.4;
}

.curso-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  overflow-y: auto;
  /* min-width: 416px; */
  max-width: 416px;
  max-height: 100vh;
  padding: 20px;
  border-left: 1px solid var(--color-light-gray);
  /* background-color: #fff; */
  background: var(--color-light-gray);
}

.curso-sidebar-background {
  background: var(--color-light-gray);
}

.curso-modal-detalhes h2 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

@media (max-width: 768px), (orientation: landscape) and (max-height: 500px) {
  .container-curso {
    flex-direction: column;
  }
  .curso-profile {
    flex-direction: column;
    /* align-items: center; */
    /* gap: 0; */
  }
  .curso-profile__column {
  }

  .curso-profile__column:first-child {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .curso-profile__menu {
    flex-direction: column;
  }

  .aula-thumbnail img {
    width: 120px;
    height: 80px;
  }

  .aula-thumbnail {
    min-width: 120px;
    max-width: 120px;
  }

  .aula-thumbnail-status {
    margin-bottom: 0.8rem;
  }

  .curso-sidebar-background {
    background: var(--color-light-gray);
  }

  .aula-info p {
    text-align: left;
  }
}
