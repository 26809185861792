#footer {
  /* background-color: var(--color-orange-light); */
  background-color: #dbe8ff;
  padding: 3rem;
  font-family: var(--header-font-family);
  margin: 0;
  align-items: center;
}

#footer .row {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0;
  align-items: flex-start;
}

#footer .row .col {
  padding: 0.8rem;
}

#footer .row .col:last-child {
  flex: 0 0 100%;
}

#footer .row .col h1 {
  text-transform: uppercase;
  font-size: 1rem;
}

#footer a,
#footer a:visited,
#footer .row .col p {
  color: var(--color-body-text-light);
  text-decoration: none;
}

/* footer a hover vai ter um box-shadow */
#footer a:hover {
 text-shadow: 0 0 2px rgba(0,0,0,0.3);
}

body.dark #footer a:hover {
  text-shadow: 0 0 0px rgba(255,255,255,0.8);
}

#footer .nav-item {
  text-transform: uppercase;
}

#copyright {
}

#footer .col p {
  display: flex;
  align-items: center;
}

#footer .col p svg {
  margin-right: 0.5rem;
}
