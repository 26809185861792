video::-internal-media-controls-download-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    overflow: hidden;
}

video::-webkit-media-controls-panel {
    /* width: calc(100% + 30px);  */
}

.video-container {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video-player {
  position: relative;
  width: 100%; /* Garante que o contêiner ocupe a largura disponível */
  /* height é controlado pelo React */
}

.video-player > div > iframe {
  height: 400px !important;
}

/* media-player portrait = em pé
 */
media-player[aspect-ratio="0.5625"],
.video-portrait > media-player {
  width: 340px;
  --media-aspect-ratio: 9/16;
}

.video-landscape > media-player {
    --media-aspect-ratio: 16/9;
}

.video-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 10px 0;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: var(--color-blue-logo);
    border-radius: 5px;
}

input[type=range]::-webkit-slider-thumb {
    border: 1px solid lightgrey;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--color-blue-logo);
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: var(--color-blue-logo);
    border-radius: 1.3px;
}

input[type=range]::-moz-range-thumb {
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #2A6495;
    border-radius: 2.6px;
}

input[type=range]::-ms-fill-upper {
    background: var(--color-blue-logo);
    border-radius: 2.6px;
}

input[type=range]::-ms-thumb {
    height: 36px;
    width: 16px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}


:where(media-player[data-fullscreen]) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

:where(media-player[data-fullscreen] media-outlet) {
    object-fit: contain;
    /* position: fixed; */
    /* top: 0; */
    /* left: 0; */
    /* right: auto; */
    /* bottom: 0; */
    /* max-width: 100%; */
    /* max-height: 100%; */
    /* height: calc(var(--vh, 1vh) * 100); */
    /* width: calc(var(--vw, 1vw) * 85); */
    width: 100%;
    height: 100%;
}

:where(media-player[data-fullscreen][fullscreen-orientation="landscape"] media-outlet) {
    /* max-width: 75% !important; */
}


.video-overlay {
    background-color: rgba(0, 0, 0, 0.1); /* ligeira cor preta */
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-overlay::before {
    /* content: '▶️'; [> ícone de play <] */
    content: ''; 
    font-size: 3rem;
    color: black;
}

@media (max-height: 430px) {
    :where(media-player[data-fullscreen][fullscreen-orientation="landscape"] media-outlet) {
        max-width: 85% !important;
    }
}
