.Toastify .Toastify__toast-body {
  text-align: center;
  font-size: 0.9rem;
  /* color: var(--color-gray); */
  color: whitesmoke;
  /* padding: 50px; */
}

.Toastify .Toastify__toast-theme--light {
   /* background-color: var(--color-light-gray); */
   background-color: var(--color-purple);
   /* color: var(--color-body-text-light); */
   color: whitesmoke;
}

.Toastify .Toastify__close-button {
   /* color: var(--color-body-text-light); */
   color: whitesmoke;
}

/* toastify-icon-color-error */
.Toastify .Toastify__toast-body svg {
  /* fill: var(--color-blue-logo); */
  fill: whitesmoke;
}

.Toastify .Toastify__toast--error .Toastify__toast-body svg {
  fill: red;
}

.Toastify .Toastify__toast--error {
   /* background-color: var(--color-light-pink); */
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 40% !important;
    transform: translateY(-40%) !important;
  }
}
