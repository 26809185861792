.container-user {
  margin-bottom: 1rem;
}

.user-profile {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  /* border: 1px solid red; */
}

.user-profile__column {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* border: 1px solid blue; */
}

.user-profile__column:first-child {
  max-width: 180px;
}

.user-profile__column p {
  margin: 1rem 0 0.5rem;
  /* padding: 0; */
}

.user-profile__image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 0.3rem;
}

.user-profile__menu {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.user-profile__follow-btn,
.user-profile__message-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1px;
  padding: 0.5rem 1rem 0.5rem 3rem;
  border: none;
  /* border: 1px solid white; */
  /* border-radius: 4px; */
  background-color: #c9e5c9;
  color: #000;
  cursor: pointer;
  /* text-transform: uppercase; */
  font-size: 0.8rem;
  line-height: 1.5rem;
}

.user-profile__message-btn {
  background-color: #c9e5c9;
  /* background-color: #e3f2e3; */
  /* border-top: 1px solid var(--color-light-gray); */
}

.user-profile__column svg {
  margin-right: 0.5rem;
}

.user-profile__follow-btn:hover,
.user-profile__message-btn:hover {
  opacity: 0.8;
}

.user-profile__send-message-title,
.user-profile__username {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: var(--color-light-gray);
  background-color: var(--color-blue-logo);
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--color-white);
}

.user-profile__social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 0rem;
  /* border: 1px solid var(--color-light-gray); */
}

.user-profile__social-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-start; */
  justify-content: center;
  padding: 0.5rem 0.2rem;
  border: 1px solid var(--color-light-gray);
  font-size: 0.9rem;
}

.user-profile__social-item:hover {
  background-color: var(--color-light-gray);
}


.user-profile__social-item svg {
  fill: var(--color-blue-logo);
  color: var(--color-blue-logo);
}

.user-profile__social-item a {
  word-break: break-all;
}

.user-profile__website,
.user-profile__website a, .user-profile__website a:visited {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  /* color: var(--color-blue-logo); */
  color: var(--color-body-light-text);
}


.user-profile__bio {
  /* border: 1px solid var(--color-light-gray); */
  padding: 0 1rem 1rem;
  font-size: 1rem;
}

.user-profile__register-date {
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 0.9rem;
}

.user-profile__artigos-list {
  font-size: 0.9rem;
}

.user-profile__tabs {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-light-gray);
  margin-bottom: 1rem;
}

.user-profile__tabs h2 {
  display: inline-block;
  margin-right: 0.3rem;
  margin-bottom: 0;
  cursor: pointer;
}

.user-profile__artigos-title {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 1rem 0;
  padding: 5px;
  display: inline-block;
  /* position: relative; */
  color: whitesmoke;
  background: var(--color-blue-logo);
}

.user-profile-inactive-tab {
  background-color: var(--color-gray) !important;
}

/* user-information */

.user-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* font-size: 1rem; */
}

.user-information-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.user-information-field label {
  font-weight: 400;
  color: var(--color-gray);
  margin: 10px 0 0px;
  font-size: 0.9rem;
}

.user-information-field input[type="text"] {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  padding: 10px 10px;
  /* font-size: 1rem; */
  /* width: 100%; */
}

.user-information-input-prefix {
  border: 0px solid var(--color-light-gray);
  border-radius: 0px;
  padding: 10px 10px;
  background-color: var(--color-light-gray);
  color: var(--color-gray);
  z-index: 20;
  margin-right: -5px;
}

.user-information-field input[type="checkbox"] {
  margin-right: 5px;
}

.user-information-field input[readonly] {
  background-color: transparent;
  border: none;
  cursor: default;
  font-weight: 400;
  color: var(--color-gray);
}

.user-information-field input {
  color: var(--color-dark-gray);
  flex-grow: 1;
  font-size: 0.9rem;
}

.user-information-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px 0;
  font-size: 0.9rem;
}

.right-column > .user-information-header:not(:first-child) {
  margin-bottom: 0.5rem;
}

.user-information-header-title {
  min-width: 9rem; /* ajuste este valor conforme necessário */
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-gray);
}

.user-information-header-name {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-dark-gray);
}

.user-information-field textarea {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 0.9rem;
  resize: none;
  /* 5 linhas */
  height: 13rem;
}

.user-information-input-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 0.9rem;
}
/* fim user-information */



@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .container-user {
    flex-direction: column;
  }

  .user-profile {
    flex-direction: column;
    /* align-items: center; */
    /* gap: 0; */
  }
  .user-profile__column {
  }

  .user-profile__column:first-child {
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    gap: 1rem;
  }

  .user-profile__menu {
    flex-direction: column;
  }
}
