.viewbot-header,
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.viewbot-header {
  justify-content: flex-start;
}

.viewpost-container,
.viewbot-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.viewbot-header a {
  color: var(--color-body-text-light);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
}

.viewbot-header span {
  margin-right: 10px;
  margin-left: 10px;
}

.viewbot-header h1 {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}

.viewuser-label,
.viewmestre-label,
.viewbot-label {
  padding: 20px;
  background-color: var(--color-light-gray);
  margin: 0;
  border: 1px solid var(--color-light-gray);
}

.viewuser-label,
.viewmestre-label {
  min-width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewmestre-field,
.viewuser-field {
  display: flex;
  flex: 1;
}

.viewuser-field,
.viewmestre-field,
.viewbot-field {
  padding: 20px;
  border: 1px solid var(--color-light-gray);
  margin: 0;
  display: block;
}

p.viewmestre-field {
  /* forçar o wrap */
  word-break: break-all;
}

.dashboard-button-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem 0;
}

.dashboard-user-button-container {
  display: flex;
  flex: 1;
  margin-top: 1rem;
  justify-content: space-between;
}


.black-button {
  background-color: var(--color-blue-logo) !important;
  color: var(--color-white) !important;
}

.blue-button {
  background-color: var(--color-blue-logo-old) !important;
  color: var(--color-white) !important;
}

.red-button {
  background-color: var(--color-dark-red) !important;
  color: var(--color-white) !important;
}

.green-button,
button.green-button,
div > button.green-button {
  background-color: rgba(0, 100, 0, 1) !important;
  color: var(--color-white) !important;
}

.gray-button,
.gray-button:hover {
  background-color: var(--color-light-gray) !important;
  color: var(--color-body-text-light) !important;
  opacity: 0.8;
  cursor: not-allowed;
}

button.dashboard-button,
a.dashboard-button, a.dashboard-button:visited {
  font-size: 0.8rem;
  font-weight: 400;
  /* text-transform: uppercase; */
  color: var(--color-body-text-light);

  /* background-color: var(--color-light-gray); */
  /* border: 0; */
  background-color: white;
  border: 1px solid var(--color-light-gray);

  border-radius: 4px;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
}

button.dashboard-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.dashboard-change-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
}

.dashboard-change-photo svg {
  color: var(--color-gray);
  font-size: 1.2rem;
  cursor: pointer;
}

.viewmestre-content {
  display: flex;
}

.viewmestre-content svg {
  cursor: pointer;
}

.dashboard-create-post,
.dashboard-create-bot {
  display: flex;
  justify-content: flex-end;
}

.dashboard-create-post svg {
  cursor: pointer;
}

.dashboard-create-bot a {
  text-decoration: none;
  /* text-transform: uppercase; */
  font-size: 1rem;
  vertical-align: middle;
}

.personalidades-list,
.bots-list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px;
}

.personalidades-list {
  justify-content: flex-start;
  padding: 0;
}

.bot-card {
  display: flex;
  width: 22%;
  height: 80px;
  margin: 10px;
  background-color: #ebebeb;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  cursor: pointer;
}

/* dashboard card */
.dashboard-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 0.9rem;
}

.dashboard-content h1 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.dashboard-cards {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  /* padding: 2rem; */
  max-width: calc((7rem + 1.2rem) * 5); /* N cards por linha */
}

.dashboard-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* width: 6rem; */
  /* height: 6rem; */
  width: 9rem;
  height: 9rem;
  margin: 0.6rem;
  background-color: white;
  border-radius: 5%;
  /* box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2); */
  border: 1px solid var(--color-light-gray);
  color: var(--color-body-text-light);
  padding: 0.5rem;
  cursor: pointer;
}

.dashboard-card:hover {
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2);
  /* border: 0px transparent; */
}

.dashboard-card-icon {
  font-size: 2rem;
}

.dashboard-card-icon svg {
  fill: var(--color-blue-logo);
}

.dashboard-card-icon-admin svg {
  /* fill: red !important; */
}

.dashboard-card-text {
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
}

.dashboard-header-label {
  display: flex;
  flex: 1;
  padding: 10px;
  background-color: var(--color-light-gray);
  margin: 1rem 0 0.5rem 0;
}

.dashboard-header-label h3 {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  color: var(--color-gray);
}

/* fim dashboard card */

.personalidade-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: var(--color-light-gray);
  border-radius: 10px;
  padding: 5px;
  width: 220px;
  height: 50px;
  flex-direction: row;
}

/* -card not active hover */
.personalidade-card:not(.personalidade-card-active):hover,
.bot-card:hover {
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.5);
}

.personalidade-card-active {
  background-color: var(--color-blue-logo);
  color: white;
  /* box-shadow: 0px 2px 8px 0px rgba(0,0,55,0.5); */
}

.personalidade-card-text {
  text-align: center;
  /* color: var(--color-body-text-light); */
}

/* os dois primeiros cards tem um margin-right: 10px; */
/* .personalidade-card-text:nth-child(-n+2) { */
.personalidade-card-text:nth-child(1) {
  margin-right: 10px;
}

.personalidade-card-icons {
  white-space: nowrap;
}

.personalidade-card-icons svg:not(:last-child) {
  margin-right: 5px;
}

.personalidade-card-info {
  position: absolute;
  display: flex;
  width: 200px;
  background-color: #222;
  color: white;
  margin-top: 170px;
  padding: 10px;
  border-radius: 10px;
}

.icon-garagem-active {
  fill: green !important;
  color: green !important;
  opacity: 0.7;
}

.icon-garagem-inactive {
  fill: darkred !important;
  color: darkred !important;
  opacity: 0.7;
}

.logs-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid var(--color-light-gray);
}

.logs-col {
  display: flex;
  flex-direction: row;
  /* margin: 0 0 1rem 0; */
  /* flex: 1 1; */
  justify-content: flex-end;
  align-items: flex-start;
}

.logs-date {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.logs-col select {
  margin-left: 0.5rem;
  padding: 0.5rem;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
  color: var(--color-body-text-light);
}

.logs-col select:first-child {
  margin-left: 0;
}

.bot-card a {
  color: #222;
  text-decoration: none;
}

.bot-card a span {
  opacity: 0.3;
}

#editBot .form-group {
  /* position: relative; */
}

#deleteBot {
  max-width: 600px;
}

#user-picture {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  border: 2px solid var(--color-light-pink);
}

.left-column {
  width: 180px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.right-column {
  flex-grow: 1;
}

.changePictureModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0,0,0,0.8);
}

.changePictureModalContent {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  width: 400px;
  max-width: 400px;
  border: 0;
  border-radius: 15px;
  padding: 0px;
  background-color: var(--color-background-depoimento-dark);
}

.changePictureModalContentHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #333;
  padding: 30px;
  font-size: 1.4rem;
  color: var(--color-body-text-dark);
}

.changePictureModalContentBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.changePictureModalContentBody div,
.changePictureModalContentBody button {
  background: none;
  border: none;
  padding: 20px;
  width: 100%;
  color: var(--color-body-text-dark);
  border-bottom: 1px solid #333;
  font-size: 1.1rem;
  cursor: pointer;
}

.changePictureModalContentBody button:nth-child(3) {
  border-bottom: 0px;
}

.posts-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.posts-list p {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 0.8rem;
  color: #666;
  margin: 0 0 0.5rem 0;
}

.posts-list table {
  width: 100%;
  font-size: 0.9rem;
}

.posts-list table th {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  background-color: var(--color-blue-footer);
  color: whitesmoke;
}

.posts-list table td:nth-child(n+2),
.posts-list table th:nth-child(n+2) {
  text-align: center;
}

.posts-list table td {
  padding: 1.2rem 0.3rem;
}

div.posts-list .MuiTableContainer-root table td a {
  text-decoration: none;
}

/* um tr par */
.posts-list table tr:nth-child(even) {
  background-color: var(--color-orange-light);
}

/* coluna de status - terceira coluna */
.posts-list table tr td:nth-child(3) {
  white-space: nowrap;
}

/* coluna de ação */
.posts-list table tr td:last-child {
  min-width: 4rem;
  white-space: nowrap;
  text-align: left;
}

/* icones de ação */
.posts-list table tr td svg {
  margin: 0 5px;
  cursor: pointer;
  fill: var(--color-gray);
  color: var(--color-gray);
}

.checkbox-field {
  display: inline;
}

.checkbox-label {
  display: inline;
  font-weight: 400;
  color: var(--color-gray);
}
.small-form {
  display: block;
  max-width: 500px;
  text-align: left;
  margin: 0 auto;
}

.dashboard-bar-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.dashboard-bar-tags span.dashboard-tag {
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  border-radius: 1.2rem;
  background-color: var(--color-light-gray);
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
}

.dashboard-tag svg {
  margin-left: 0.5rem;
  fill: var(--color-gray);
  cursor: pointer;
}

.dashboard-subtitle {
  display: block;
  padding: 0;
  margin: 1rem 0 0.5rem;
}

.dashboard-subtitle h1 {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  padding: 5px;
  display: inline;
  background: var(--color-light-gray);
  color: inherit;
}

#dashboard > div:nth-child(2) {
  /* primeiro dashboard-subtitle */
  margin-top: 0 !important;
}

.dashboard-filter-posts {
  display: flex;
  flex-direction: row;
}

.overlay-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999; /* Ajuste o valor do zIndex conforme necessário */
}

.overlay-container > p {
  background-color: var(--color-blue-logo);
  color: whitesmoke;
  padding: 1rem;
  border-radius: 4px;
}

.upload-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.upload-container svg {
  margin-right: 1rem;
  fill: var(--color-blue-logo);
  color: var(--color-blue-logo);
  font-size: 2.5rem;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}
.pagination li {
  margin: 0;
}
.pagination li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  display: inline-block;
  min-width: 10px;
  text-align: center;
}
.pagination li a:hover {
  background-color: #eee;
}
ul > li.active-page > a,
ul > li.active-page > a:hover {
  background-color: var(--color-blue-logo);
  border-color: var(--color-blue-logo);
  color: white !important;
  cursor: default;
}
ul > li.previous-page a, ul > li.previous-page a:hover,
ul > li.next-page a, ul > li.next-page a:hover,
ul > li.disabled-page a, ul > li.disabled-page a:hover {
  border: none;
  border-color: transparent;
  cursor: pointer;
  background-color: transparent;
}

ul > li.disabled-page a, ul > li.disabled-page a:hover {
  cursor: default;
  color: #999;
}

.legend {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem 0;
  /* margin-left: auto; */
  border: 2px solid var(--color-light-gray);
  border-radius: 14px;
  padding: 20px;
  gap: 0.5rem;
}

.legend-item svg {
  margin-right: 0.5rem;
}

.legend-item span {
  font-size: 0.9rem;
  color: var(--color-gray);
}

/* 
  div gerado melo ModalImage
  quero ele 100% para funcionar com 
  a ImageList
 */
div.modal-image-container > div {
  height: 100%;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .dashboard-card {
    width: 6rem;
    height: 6rem;
  }

  .logs-row {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .viewuser-label,
  .viewmestre-label {
    font-size: 0.8rem;
    min-width: 100px;
  }

  .viewmestre-label.flex-start {
    justify-content: flex-start;
  }

  .user-information-header-name {
    word-break: break-all;
  }

  .left-column {
    width: 100%;
  }

  .personalidade-card {
    width: 100%;
  }

  .personalidade-card-info {
    width: 80%;
  }

  .personalidade-card-text:nth-child(1) {
    margin-right: 20px;
  }

  .personalidade-card-icons svg {
    width: 25px;
    height: 25px;
  }

  .personalidade-card-icons svg:not(:last-child) {
    margin-right: 15px;
  }
}
