body.dark {
  --color-blue-generic: #003892;
  --color-gray: #aaa;
  --color-light-gray: #333;
  --color-medium-gray: #333;
  --color-dark-purple: rgba(198, 161, 255, 0.4);
  --color-dark-blue: rgba(0, 0, 0, 0.4);
  --color-blue-footer: rgba(0, 0, 139, 0.5);
  --color-background: #191919;
  /* --color-background: #0A1D56; */
  --color-background-2: rgba(0, 0, 0, 0);
  --color-success-div: rgba(0, 255, 0, 0.4);
  --color-text: #fff;
  --color-warning-bg: rgb(25, 18, 7);
  --color-warning-text: rgb(255, 226, 183);
  --color-info-bg: rgb(7, 19, 24);
  --color-info-text: rgb(184, 231, 251);
  --color-error-bg: rgb(22, 11, 11);
  --color-error-text: rgb(244, 199, 199);
  --color-success-bg: rgb(12, 19, 13);
  --color-success-text: rgb(204, 232, 205);
  --color-orange-light: rgba(130, 130, 130, 0.2);
}

body.dark {
  background-color: var(--color-background);
  color: var(--color-body-text-dark);
}

body.dark .home-post-item-titulo h1,
body.dark .home-post-item-titulo a {
  color: var(--color-body-text-dark) !important;
  text-decoration: none !important;
}

body.dark .viewpost-bar-tags span.viewpost-tag {
  color: var(--color-body-text-light);
}

body.dark .home-post-item {
  background: var(--color-background-2);
}

body.dark .home-post-item hr {
  /* border-top: 1px solid var(--color-body-text-light); */
}

body.dark .search-box input {
  background-color: transparent;
  border: 0;
}

body.dark .links-rapidos-container li svg {
  fill: var(--color-body-text-dark);
  color: var(--color-body-text-dark);
}

body.dark .home-sidebar h1,
body.dark .viewpost-suggestions h2 {
  color: var(--color-body-text-dark);
  background-color: #333;
}

body.dark .sidebar-post a.sidebar-post-link,
body.dark .sidebar-services a.sidebar-service-link {
  color: var(--color-body-text-dark);
}

body.dark .main-container section a {
  text-decoration: underline;
}

body.dark .breadcrumb-container a {
  text-decoration: none !important;
}

body.dark .nav-item-search .search-box {
  background-color: transparent;
  border: 1px solid var(--color-light-gray);
}

body.dark .dashboard-card {
  /* color: var(--color-body-text-dark); */
}

body.dark .dashboard-card:hover {
  /* explaing the box-shadow:
    * 1st value: horizontal offset
    * 2nd value: vertical offset
    * 3rd value: blur radius
    * 4th value: spread radius
    * 5th value: color
  */
  box-shadow: 0px 0px 8px 8px var(--color-light-gray);
}

body.dark .notifications-menu {
  background-color: #000;
}

body.dark .floating-menu {
  background-color: #000;
}

body.dark a.nav-item:hover:not(.nav-item-write):not(.nav-item-icon),
body.dark .notifications-menu__item:hover,
body.dark .floating-menu__item:hover {
  background-color: whitesmoke;
  opacity: 0.5;
}

body.dark #footer {
  background-color: var(--color-blue-footer);
}

body.dark #footer a,
body.dark #footer a:visited,
body.dark #footer .row .col p {
  color: var(--color-body-text-dark);
}

body.dark #footer a:hover {
  text-shadow: 0;
  box-shadow: 0;
}

body.dark .viewpost-bar-social svg {
  color: var(--color-body-text-dark);
  fill: var(--color-body-text-dark);
}

body.dark textarea,
body.dark select,
body.dark input {
  background-color: transparent;
  border: 1px solid var(--color-light-gray);
  color: var(--color-body-text-dark);
}

body.dark #pagamento input {
  border: 1px solid var(--color-gray);
}

body.dark .dashboard-button,
body.dark a.dashboard-button,
body.dark a.dashboard-button:visited {
  background-color: var(--color-light-gray);
  color: var(--color-body-text-dark);
}

body.dark .anexos-col-image span {
  color: var(--color-body-text-dark);
}

body.dark .react-confirm-alert-body {
  background-color: rgba(0, 0, 0, 0.9);
}

body.dark
  div.react-confirm-alert
  div.react-confirm-alert-button-group
  button:last-child {
  color: var(--color-body-text-dark);
}

body.dark #contato a,
body.dark #contato a:visited {
  color: var(--color-body-text-dark);
}

body.dark .contact-list li svg,
body.dark .user-profile__social-item svg,
body.dark .upload-container svg {
  fill: var(--color-body-text-dark);
  color: var(--color-body-text-dark);
}

body.dark .inbox-contact-back svg {
  color: var(--color-body-text-dark);
  fill: var(--color-body-text-dark);
}

body.dark .add-comment-header,
body.dark .anexos-header {
  background-color: var(--color-light-gray);
}

body.dark .react-loading-skeleton {
  background-color: var(--color-light-gray);
  --base-color: #444;
  --highlight-color: #666;
}

body.dark .blue-button {
  /* background-color: var(--color-blue-footer) !important; */
}

body.dark .char-counter {
  background-color: var(--color-light-gray);
}

body.dark .main-container a,
body.dark .main-container a:visited,
body.dark .mobile-menu a,
body.dark .mobile-menu a:visited {
  color: var(--color-body-text-dark);
}

body.dark .main-container a,
body.dark .main-container a:visited {
  /* text-decoration: underline; */
}
body.dark .user-profile-inactive-tab {
  background-color: var(--color-light-gray) !important;
}

body.dark .brinquedo-info,
body.dark .brinquedo-observacoes {
  background-color: var(--color-light-gray);
  color: var(--color-body-text-dark);
}

body.dark .brinquedo-card-info span {
  color: var(--color-body-text-light);
}

body.dark .slick-prev:before,
body.dark .slick-next:before {
  color: var(--color-body-text-dark) !important;
}

body.dark .sidebar-arrow-hide svg {
  /* color: transparent; */
}

body.dark .sidebar-arrow-show svg {
  color: var(--color-body-text);
}

body.dark .slick-dots li button:before {
  color: var(--color-body-text-dark) !important;
}

body.dark .brinquedo-preco h2 {
  background-color: var(--color-light-gray);
}

body.dark .planos,
body.dark .plano {
  background-color: transparent;
  color: var(--color-body-text-dark);
}

body.dark .black-button {
  background-color: rgba(0, 0, 0, 0.4) !important;
  color: var(--color-body-text-dark) !important;
}

body.dark .plano-bronze,
body.dark .plano-prata,
body.dark .plano-ouro,
body.dark .plano-diamante,
body.dark .plano-vitalicio {
  border: 1px solid var(--color-light-gray);
}

body.dark .plano h2 {
  border-bottom: transparent;
}

body.dark button.plano-button {
  border: 1px solid var(--color-light-gray) !important;
  background-color: rgb(57, 81, 179) !important;
}

body.dark .brinquedo-card {
  background-color: transparent;
  box-shadow: 0 0 0 1px var(--color-light-gray);
}

body.dark .brinquedo-card:hover {
  box-shadow: 0 0 0 2px var(--color-light-gray);
}

body.dark .chart-select-date svg polyline {
  stroke: var(--color-body-text-dark);
}

body.dark .brinquedo-card svg {
  color: var(--color-body-text-dark);
  fill: var(--color-light-gray);
}

/* quando passa o mouse sobre uma barra do gráfico */
body.dark path.recharts-tooltip-cursor {
  fill: var(--color-light-gray);
}

body.dark .topic-item-titulo h1,
body.dark .topic-item-titulo a {
  color: var(--color-body-text-dark) !important;
  text-decoration: none !important;
}

.topic-item:hover {
  background-color: var(--color-light-gray);
  border-radius: 10px;
}

body.dark .chart-progress-bar {
  /* background-color: rgba(0, 128, 0, 0.5); */
  background-color: rgba(0, 56, 146, 1);
}

body.dark .recharts-default-tooltip {
  background-color: var(--color-light-gray) !important;
  color: var(--color-body-text-dark) !important;
}

body.dark .recharts-tooltip-item {
  color: var(--color-body-text-dark) !important;
  font-weight: bold;
}

body.dark a.dashboard-button {
  text-decoration: none !important;
}

body.dark .tag-suggestions {
  background-color: var(--color-light-gray);
}

body.dark .suggested-tag:hover,
body.dark .suggested-tag.selected {
  background-color: #666;
}

body.dark .pagination li a,
body.dark a.garagem-link,
body.dark a.garagem-link-button,
body.dark .user-profile__social-item a,
body.dark .user-profile__website a {
  text-decoration: none !important;
}

body.dark .pagination li a {
  border: 0;
}

body.dark .dashboard-tag {
  color: var(--color-body-text-light);
}

body.dark .dashboard-tag svg {
  color: var(--color-light-gray);
  fill: var(--color-light-gray);
}

body.dark .is-invalid,
body.dark .is-invalid:focus {
  border-color: #df2e38;
  border-left: 8px solid #df2e38;
}

body.dark .success {
  color: var(--color-body-text-dark);
  background-color: var(--color-success-div);
  padding: 1rem;
  /* border-radius: 10px; */
  margin: 0.5rem 0;
}

body.dark .markdown-container blockquote {
  color: var(--color-body-text-dark) !important;
}

/* classes do MUI Select */
body.dark .mui-select {
  /* color: var(--color-body-text-dark); */
  /* margin-right: 0.2rem; */
}

body.dark .curso-sidebar {
  background-color: var(--color-background);
}

body.dark .curso-sidebar-background {
  /* background-color: var(--color-blue-logo); */
}

body.dark .floating-menu__item {
  color: #ddd;
}

body.dark .floating-menu__item:hover {
  background-color: #111;
}

body.dark .user-information-header-name {
  color: var(--color-body-text-dark);
}

@media (max-width: 992px), (orientation: landscape) and (max-height: 500px) {
  body.dark #mobile-menu-suspenso {
    background-color: var(--color-light-gray);
    color: var(--color-body-text-dark);
  }
  body.dark .home-sidebar-relative {
    background-color: rgba(1, 1, 1, 1);
  }

  body.dark #footer {
    background-color: var(--color-blue-footer);
  }

  body.dark #footer a,
  body.dark #footer a:visited,
  body.dark #footer .row .col p {
    color: var(--color-body-text-dark);
  }

  body.dark #footer a:hover {
    text-shadow: 0;
    box-shadow: 0;
  }
  body.dark .curso-sidebar-background {
    background-color: var(--color-blue-logo);
  }
}
