@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.slick-prev,
.slick-next {
  color: var(--color-blue-logo) !important;  /* Define a cor das setas */
}

.slick-next {
  right: 5px !important;
  z-index: 200;
}

.slick-prev {
  left: -10px !important;
  z-index: 200;
}

.slick-prev:before, 
.slick-next:before {
  /* color: var(--color-blue-logo) !important;  [> Define a cor das setas <] */
  color: black !important;  /* Define a cor das setas */
  /* width: 30px !important; [> Define a largura das setas <] */
  /* height: 30px !important; [> Define a altura das setas <] */
  font-size: 35px !important; /*[> Define o tamanho das setas <]*/
}

.slider-container {
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
}

.slider-label {
  display: block;
  font-size: 1rem;
  color: var(--color-gray);
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
}

.slick-dots li button:before {
  color: var(--color-blue-logo) !important;  /* Define a cor dos pontos */
  font-size: 15px !important;  /* Define o tamanho dos pontos */
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;  /* Define a opacidade dos pontos */
}
