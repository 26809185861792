.home-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  max-width: 368px;
  /* padding: 1.4rem 0rem 1.4rem 1.4rem; */
  padding: 0;
  border-left: 1px solid var(--color-light-gray);
}

.sidebar-closed {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  max-width: 20px;
  /* padding: 1.4rem 0rem 1.4rem 1.4rem; */
  padding: 0 !important;
  border-left: 1px solid var(--color-light-gray);
}

.home-sidebar-relative {
  position: relative;
  /* padding: 1.4rem 0rem 1.4rem 1.4rem; */
  padding: 1rem;
  /* border: 1px solid green; */
  min-height: 100vh;
}

span.clickable-border {
  content: "";
  position: absolute;
  top: 0;
  left: -5px;
  width: 15px;
  height: 100vh;
  /* background: red; */
  cursor: pointer;
}

.home-sidebar-absolute-close {
  position: absolute;
  top: -5px;
  left: -11px;
  /* border: 1px solid red; */
  background: green;
}

.home-sidebar-absolute-open {
  position: absolute;
  top: 30%;
  left: -37px;
  /* border: 1px solid red; */
}

.home-sidebar-content {
  /* efeito de transição entre o normal e o sticky */
  transition: padding-top 1s ease;
  min-height: 100vh;
}

.home-sidebar-content.sticky {
  position: sticky;
  top: 0;
  padding-top: 1.4rem;
}

.home-sidebar p {
  font-size: 0.9rem;
}

.search-box-container {
  margin-bottom: 1rem;
}

.search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border: 1px solid var(--color-light-gray);
  border-radius: 6px;
  margin-bottom: 20px;
}

.search-box input {
  flex: 1;
  border: none;
  outline: none;
  /* font-size: 1rem; */
  padding: 0;
  margin: 0;
  margin-left: 10px;
  color: var(--color-gray);
}

.search-box svg {
  cursor: pointer;
}

/* toda div imediatamente abaixo de .home-sidebar 
 * exceto a primeira
 */
.home-sidebar > div:not(:first-child) {
  margin-top: 10px;
}

.home-sidebar h1,
.viewpost-suggestions h2 {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  padding: 5px;
  /* padding-bottom: 10px; */
  display: inline;
  position: relative;
  /* color: var(--color-blue-logo); */
  color: whitesmoke;
  /* background: var(--color-blue-logo); */
  background: #000;
}

.viewpost-suggestions h2 {
  font-size: 0.9rem;
}

.home-sidebar h1:after {
  content: "";
  position: absolute;
  display: inline-block;
  bottom: 0px;
  left: 0;
  width: 150%;
  /* border-bottom: 2px solid var(--color-blue-logo); */
  /* margin-bottom: 5px; */
  /* background: var(--color-blue-logo); */
  /* transform: scaleX(0); */
  /* transform-origin: right; */
  /* transition: transform 0.3s ease; */
}

/* primeiro elemento apos o h1 deve ter uma margin-top */
.home-sidebar h1 + * {
  margin-top: 20px;
}

/* primeiro elemento pai do h1 deve ter uma margin-top */
.home-sidebar h1 ~ * {
  /* margin-bottom: 60px; */
}

.home-destaques {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: justify;
  text-rendering: geometricPrecision;
}

.home-destaque-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.7rem;
  margin: 0px 0px 10px;
  border-radius: 10px;
}

.home-destaque-item h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  padding-bottom: 0px;
}

.home-destaque-item h3 {
  cursor: pointer;
}

.home-destaque-item a.home-destaque-item-link {
  /* display: inline; */
  /* justify-self: flex-end; */
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0px 0px 0px;
  padding: 0 10px;
  color: var(--color-gray);
}

#home-depoimentos {
  display: flex;
  flex-direction: column;
}

.depoimento {
  display: flex;
  flex: 1;
  /* font-style: italic; */
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin: 10px;
  background-color: var(--color-light-gray);
  /* color: #fff; */
  border-radius: 20px;
  font-size: 0.9rem;
}

.sidebar-post-link {
  /* display: list-item; */
  /* list-style-type: disc; */
  /* list-style-position: inside; */
  margin-bottom: 10px;
}

.sidebar-post {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.sidebar-post svg {
  margin: 0.3rem 0.7rem 0 0;
  fill: var(--color-gray) !important;
  color: var(--color-gray) !important;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1.2rem;
}

.sidebar-post a.sidebar-post-link,
.sidebar-services a.sidebar-service-link {
  color: var(--color-body-text-light);
  font-size: 0.9rem;
}

a.sidebar-service-link {
  padding: 0 0.5rem;
}

.sidebar-services {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.sidebar-services svg {
  color: var(--color-blue-logo);
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.sidebar-arrow-show a {
  background: var(--color-light-gray);
}

.sidebar-arrow-hide svg {
  /* color: transparent; */
  color: var(--color-body-text);
}

.links-rapidos-container ul {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.links-rapidos-container li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  box-sizing: border-box;
}

.links-rapidos-container li svg {
  margin-left: 1rem;
  margin-right: 0.7rem;
  /* fill: var(--color-blue-logo); */
  /* color: var(--color-blue-logo); */
  fill: black;
  color: black;
}

.links-rapidos-container li a,
.links-rapidos-container li a:visited,
.links-rapidos-container button {
  background: none;
  border: none;
  color: inherit;
  /* text-align: center; */
  text-decoration: none;
  display: inline-block;
  margin: 0 0 0.6rem;
  padding: 10px 20px 0 0;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  font-size: 0.9rem;
  font-family: var(--body-font-family);
  text-decoration: none !important;
  /* transition: background-color 0.2s; */
}

.links-rapidos-container li:hover {
  /* background-color: var(--color-light-gray); */
}

.depoimentos-block {
  margin-top: 20px;
}

span.sidebar-icon {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 0.7rem;
  color: #a3a3a3;
  line-height: 1.3rem;
}

svg.sidebar-close {
  font-size: 1.3rem;
  color: var(--color-blue-logo);
  fill: var(--color-blue-logo);
  cursor: pointer;
}

/* 
 media query 
*/

@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {

  .mobile-menu {
    /* top: 60px; */
    /* background: none; */
    /* z-index: 1200; */
  }

  .home-sidebar {
    width: 100%;
    max-width: 100%;
    /* min-height: calc(100vh - 80px); */
    /* max-height: calc(100vh - 80px); */
    /* min-height: 100vh; */
    /* max-height: 100vh; */
    z-index: 1200;
  }

  .home-sidebar-relative {
    position: fixed;
    top: 0;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    min-height: 100vh;
    max-height: 100vh;

    /* transform: translateY(calc(-50% + 80px)); */
    /* min-height: calc(100vh - 80px); */
    /* max-height: calc(100vh - 80px); */

    margin: 0;
    padding: 0;
    background: white;
    overflow-y: scroll;
  }

  .sidebar-mobile-content {
    /* margin-top: 50px; */
    padding: 1rem 2rem;
    padding-bottom: 5rem;
  }
}
