.comment {
  padding: 0.5rem 0rem;
  margin-bottom: 0.5rem;
  /* font-size: 1rem; */
}

.container-comments-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}

.container-comments-header span {
  display: flex;
  align-items: center;
  color: var(--color-gray);
  font-size: 0.8rem;
  cursor: pointer;
}

.container-comments-header svg {
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: var(--color-gray);
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.comment-photo {
}

.comment .comment-photo img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 10px;
}

.comment .comment-author a,
.comment .comment-author a:visited {
  font-weight: 400;
  margin-right: 0.5rem;
  text-decoration: none;
  color: var(--color-gray);
}

.comment-author:hover {
  /* text-decoration: underline; */
}

.comment-date::before {
  content: "·";
  display: inline-block;
  margin-right: 0.5rem;
  color: var(--color-gray);
}

.comment-date {
  color: #6c757d;
  font-size: 12px;
}

.comment-border {
  border-left: 2px solid var(--color-light-gray);
  margin-left: 1rem;
  padding-left: 1rem;
}

.comment-content {
  white-space: pre-line;
  word-wrap: break-word;
  /* border-left: 2px solid var(--color-light-gray); */
  /* margin-left: 1rem; */
  /* padding-left: 1rem; */
  /* background-color: #fff; */
  transition: background-color 5s ease-out; /* transição suave */
  border-radius: 15px;
  padding: 0.5rem;
}

.highlighted-comment {
  background-color: #dbe2ff; /* cor de fundo suave */
  /* transition: background-color 5s ease-out;  */
}

.comment-content p {
  margin: 0;
}


.comment-reply,
.comment-edit,
.comment-delete {
  display: flex;
  align-items: center;
  color: var(--color-gray);
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.comment-reply svg,
.comment-edit svg,
.comment-delete svg {
  margin-right: 0.2rem;
  cursor: pointer;
  color: var(--color-gray);
  opacity: 0.5;
  font-size: 1.5rem;
}

.comment-reply.forbidden,
.comment-reply.forbidden svg {
  cursor: not-allowed;
}

.comment-footer {
  display: flex;
  margin: 1rem 0 0 0rem;
  /* padding-left: 1rem; */
}

.comment form {
  margin-top: 0.5rem;
  padding: 0;
}

.comment form textarea {
  margin: 0;
}

.comment br {
  display: none;
}

.add-comment-header {
  background: var(--color-blue-logo);
  margin: 0;
  padding: 0;
}

.add-comment-header h2 {
  text-align: center;
  padding: 0.5rem;
  margin: 0;
  color: white;
  font-size: 0.9rem;
  font-weight: 400;
}

.add-comment-help {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem;
  margin: 0;
}

/* media-query */
@media (max-width: 768px),
(orientation: landscape) and (max-height: 500px) {
  .comment-footer {
    flex-wrap: wrap;
  }
}
